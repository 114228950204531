<template>
  <section class="container" id="top">
    <div class="page-title-banner">
      <h1>Mysterybox.py</h1>
      <h2>Crafting a C.O.D Mysterybox using Python, and an Arduino</h2>
    </div>
    <div class="intro">
      <figure>
        <img
          src="../../assets/img/projects/mysterybox.jpg"
          alt="Mystery box image"
        />
        <figcaption>
          N.B. The completed code assets for this project can be found on my
          <a
            class="external-ref"
            href="https://github.com/ktbrawley/MysteryBox_Py"
            >github</a
          >.
        </figcaption>
      </figure>

      <h2 class="section-title">Background</h2>
      <p>
        When attempting to learn any new skill, it can be beneficial to imbue a
        little of what already interests you, and which will motivate you to
        practice. Granted. That’s only half the battle; you still need to put in
        the time. However, it does make the experience more enjoyable.
      </p>
      <p>
        The title of this project should have given the game away (pun intended)
        – I am a gamer. As such, I thought of no better pool of inspiration to
        draw from than the hundreds of games that I have played in my lifetime.
        So, then, why did I choose Call of Duty (C.O.D)?
      </p>
      <p>
        For all those unfamiliar with the C.O.D franchise, it is collection of
        First-Person Shooters which – aside from grossing billions of dollars
        since its conception - has dabbled in many things over the years:
        recreating key WWII skirmishes; high-octane single player campaigns; Kit
        Harington in space (can’t say I played that one). All of these attempts
        at something different to previous entries in the series.
      </p>
      <p>
        One, in particular, that has really stood out as a simple, yet
        enthralling concept is the infamous ‘Zombies’ mode.
      </p>

      <p>
        Now Zombies aren’t anything new in gaming. Despite their oversaturation
        in the market, I, like many others, can’t get enough of them. In that
        vein, I sought to bring life to a key feature of the Zombies mode. That
        is none other than the eponymous ‘Mystery box’.
      </p>
    </div>
    <div class="tutorial">
      <div class="design-section">
        <h2 class="section-title">Design</h2>
        <p>
          The ‘Mystery box’ serves one function in the game: allow the player
          the option to gamble the quality of their current arsenal…for a price.
          This naturally determines how effective their weapon-set is against
          the upcoming wave of the undead.
        </p>
        <p>
          Unsurprisingly, the outcome of this bet isn’t always positive; the
          pseudo-random nature of the mechanic guarantees as much. Although,
          when it does provide you with that much needed LMG, Ray-Gun, or set of
          Monkey bombs, you feel ecstatic…if not a little relieved.
        </p>
        <p>
          Ok. If the aim is to simulate this functionality in the real world,
          what do we have to consider:
        </p>
        <div class="example">
          <ol>
            <li>
              It has to allow the user to activate it.
              <i class="far fa-2x fa-hand-paper"></i>
            </li>
            <li>
              It has to pseudo-randomly select a weapon for the user.
              <i class="fas fa-2x fa-sort-numeric-up"></i>
            </li>
            <li>
              It has to present that user with the new offering.
              <i class="fas fa-2x fa-gift"></i>
            </li>
            <li>
              It has to repeat steps 1-3 until a limit is hit
              <i class="fas fa-2x fa-redo"></i>
            </li>
          </ol>
        </div>
        <p>Each of these are quite straight-forward:</p>
        <ol>
          <li>
            We give the user a mechanism to interact with the box (e.g. a
            button, switch, or key press).
          </li>
          <li>
            We use a pseudo-random system, like a number generator, to confirm
            what weapon the user receives.
          </li>
          <li>
            We have to show the user what weapon they've gained (more on that in
            the <strong>prototyping</strong> stage).
          </li>
          <li>
            And we have to limit the number of times the user can interact with
            the box (did someone pseudo-random again?)
          </li>
        </ol>
        <p>
          Before I go any further, I must address the term
          <strong>pseudo-random</strong>: this simply refers to the use of a
          predefined number range in order to simulate a seemingly random
          outcome (i.e., think about rolling a 6 sided die, and you’ll get the
          idea). Depending on the number generated (or rolled) from this range,
          an outcome can be assigned.
        </p>
        <p>
          With this in mind, we can address the limit mentioned in Step 4. In
          the game, the user can interact with the box a set number of times
          before it disappears from the current location, and teleports to a
          different position on the game map. Unfortunately, my powers of
          programming don't extend as far out as transwarp beaming, but a limit
          can still be enforced by employing a similar pseudo-random approach.
        </p>
      </div>
      <div class="code-section">
        <h2 class="section-title">Development</h2>
        <p>
          So, we have an outline of functionality. How do we turn that into
          python? For the sake of ease, lets look at an excerpt from the final
          product:
        </p>
        <div class="code-example">
          <figure>
            <img
              src="../../assets/img/projects/mysterybox_python.png"
              alt="The 'main loop' of the program"
            />
            <figcaption>The 'main loop' of the program</figcaption>
          </figure>
        </div>
        <p>
          At first glance, this might seem mighty complicated. Needn't fear!
          This is generally my initial reaction to any new code (especially my
          own ha!). If we approach this one line at at time, it begins to become
          clear:
        </p>
        <ol>
          <li>
            Firstly, the program checks if there are any available serial port
            connections that can be established. This ties into the integration
            with the Arduino. Something we'll focus on later in the prototyping
            phase.
          </li>
          <li>
            If a valid serial connection is found, we then tell our program that
            this is the Arduino, and we'll like to make contact with it; via the
            'init' method.
          </li>
          <li>
            Next it generates some initial conditions such as the spin limit
            (the number of times the user can interact with the box), and the
            name of the weapon generated (which will be nothing, because the
            user hasn't activated the box yet).
          </li>
          <li>
            A 'While' loop cycles, checking the following on each iteration:
          </li>
          <ul>
            <li>If the user has hit their interaction limit.</li>
            <li>If the 'weapon' generated was the Teddy Bear.</li>
          </ul>
          <li>
            If these aren't true, the program waits for the user to interact
            with it (i.e., push a button).
          </li>
          <li>After which it</li>
          <ul>
            <li>Spins the mystery box.</li>
            <li>Updates the name of the weapon generated.</li>
            <li>Increases the spin count.</li>
            <li>
              And tells the Arduino the weapon name, so it can update the LCD it
              speaks to on our behalf.
            </li>
          </ul>
          <li>
            The cycle continues until the spin limit is hit and the teddy is
            spawned. In that case, the 'quitProgram' variable is set to True, we
            play the Teddy Bear spawn sound effect, and tell the Arduino to
            reflect this change in the LCD. Afterwhich, we leave the While loop,
            and the program is terminated.
          </li>
        </ol>
        <p>Still with me? Good!</p>
      </div>
      <div class="prototype-section">
        <h2 class="section-title">Prototype</h2>
        <p>
          Now, I'll wholeheartedly admit, there were at least a half-dozen
          better ways - that I could think of - in which to approach creating a
          housing for all the necessary electronics. But limited resources, and
          a lot of laziness, got the better of me; the bane of many a developer.
        </p>

        <figure>
          <img
            src="../../assets/img/projects/mysterybox_inners.jpg"
            alt="Mystery box inners"
          />
          <figcaption>Mystery box inners</figcaption>
        </figure>
        <p>
          In the image above, amidst the tangled mess of cables and blutack,
          there isn't much to this project
        </p>
        <ul>
          <li>An Arduino Uno board.</li>
          <li>A breadboard.</li>
          <li>A lever (powered by a Servo).</li>
          <li>An LCD module.</li>
          <li>A push button on an extension board.</li>
        </ul>
        <p>
          Additionally, the Arduino board is powered by a Serial to Usb cable;
          the very means by which information is interchanged between the python
          script, and the Arduino itself. In this example, the cable is
          connected to a desktop machine running a Debug version of the python
          program in Visual Studio Code.
        </p>
      </div>
      <p>
        In order for Arduino to make use of any of these components, there is an
        additional script written in C. This contains some code which perform a
        set of actions in response to interactions with the python program.
      </p>
      <div class="code-example">
        <figure>
          <img
            src="../../assets/img/projects/mysterybox_arduino.png"
            alt="Mystery box Arduino script"
          />
          <figcaption>Mystery box Arduino script</figcaption>
        </figure>
      </div>
    </div>
    <p>
      As with the python script, the Arduino uses a looped set of instructions
      in order to 'convincing' simulate interactions with the box. These include
      opening/closing the box lid, playing sound effects similar to those in the
      game, and updating the LCD to reflect the weapon generated.
    </p>
    <figure>
      <img
        src="../../assets/img/projects/mysterybox_open.jpg"
        alt="Mystery box open lid"
      />
      <figcaption>Mystery box open lid</figcaption>
    </figure>
    <p>
      When the python program decides that the weapon/gun selection is in fact
      the 'Teddy Bear', it will feed that information to this script. This will
      update the LCD text and stop the script.
    </p>
    <figure>
      <img
        src="../../assets/img/projects/mysterybox_LCD.jpg"
        alt="Mystery box open lid"
      />
      <figcaption>Mystery box Liquid Crystal Display (up-close)</figcaption>
    </figure>
    <a class="btn hover-btn" @click="scrollToTop" id="scrollTopBtn">
      <i class="fas fa-arrow-up"></i>
    </a>
  </section>
</template>

<script>
import Vue from "vue";
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);

export default {
  name: "SaveRoomCP.vue",
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToTop() {
      const topEl = document.getElementById("top");
      this.$smoothScroll({
        scrollTo: topEl,
      });
    },
    handleScroll() {
      const scrollTopBtn = document.getElementById("scrollTopBtn");
      if (
        document.body.scrollTop >= 500 ||
        document.documentElement.scrollTop >= 500
      ) {
        scrollTopBtn.style.display = "block";
      } else {
        scrollTopBtn.style.display = "none";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding-top: 2.2rem;
  max-width: 828px;
}

p,
ul,
ol {
  text-align: left;
  margin: 1rem;
  padding: 1rem;
}

ol {
  ul {
    padding-left: 0;
  }
}

li {
  padding: 0.2rem;
}

.example {
  display: flex;
  justify-content: center;
  background: #f6ae2d;
  color: #fff;
  text-shadow: 1px 1px #000;
  font-size: 1.8rem;
}
.example li {
  padding: 1rem;
}

.example li > i {
  margin-left: 1rem;
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  ol,
  ul {
    padding-left: 6rem;
  }
}

@media (max-width: 576px) {
  .example li > i {
    font-size: 1.8rem;
  }
}

.code-example img {
  max-width: 750px;
}

figcaption {
  text-align: center;
  padding-bottom: 1rem;
  font-weight: 300;
}

.section-title,
.section-subtitle {
  text-align: left;
  padding-left: 2rem;
}
</style>
